import React, { useState } from "react";
import axios from "axios";
export default function ContactComponent() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errMessage, setErrMessage] = useState("");
  const [errMessageOpen, setErrMessageOpen] = useState(false);
  const [errMessageSent, setErrMessageSent] = useState(false);
  const HandleContact = (e) => {
    e.preventDefault();
    if (fullName === "" || email === "" || message === "") {
      setErrMessage("Populate all fields");
      setErrMessageOpen(true);
      setErrMessageSent(false);
    } else {
      axios({
        url: "https://ncbi.co.za/endpoints/email.php",
        method: "POST",
        headers: { "content-type": "application/json" },
        data: {
          fullName: fullName,
          email: email,
          message: message,
        },
      }).then((response) => {
        console.log(response.status);
        if (response.status == "200") {
          setErrMessageSent(true);
          setErrMessage("Email sent");
        }
      });
    }
  };

  return (
    <section className="contact-form-wrap section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {errMessageOpen && (
              <div className="alert alert-danger" role="alert">
                {errMessage}
              </div>
            )}

            {errMessageSent && (
              <div className="alert alert-success" role="alert">
                {errMessage}
              </div>
            )}
            <form
              id="contact-form"
              className="contact__form"
              action="https://ncbi.co.za/endpoints/email.php"
              onSubmit={HandleContact}
            >
              <h3 className="text-md mb-4">Contact Form</h3>
              <div className="form-group">
                <input
                  name="fullName"
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  required
                  onChange={(e) => setFullName(e.target.value)}
                  value={fullName}
                />
              </div>
              <div className="form-group">
                <input
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>
              <div className="form-group-2 mb-4">
                <textarea
                  name="message"
                  className="form-control"
                  rows="4"
                  placeholder="Your Message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  required
                ></textarea>
              </div>
              <button className="btn btn-main" name="submit" type="submit">
                Send Message
              </button>
            </form>
          </div>

          <div className="col-lg-5 col-sm-12">
            <div className="contact-content pl-lg-5 mt-5 mt-lg-0">
              <span className="text-muted">We are Professionals</span>
              <h2 className="mb-5 mt-2">
                Don’t Hesitate to contact with us for any kind of information
              </h2>

              <ul className="address-block list-unstyled">
                <li>
                  <i className="ti-email mr-3"></i>Email: info@ncbi.co.za
                </li>
                <li>
                  <i className="ti-mobile mr-3"></i>Phone: 0731614240 /
                  0814628155
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
