import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
export default function Nav() {
  return (
    <>
      <header className="navigation">
        <div className="header-top ">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-2 col-md-4">
                {/* <div className="header-top-socials text-center text-lg-left text-md-left">
                  <Link
                    to="#"
                    aria-label="facebook"
                  >
                    <i className="fab fa-facebook-f text-white"></i>
                  </Link>
                  <Link
                    to="#"
                    aria-label="linkedin"
                  >
                    <i className="fab fa-linkedin text-white"></i>
                  </Link>
                  
                </div> */}
              </div>
              <div className="col-lg-10 col-md-8 text-center text-lg-right text-md-right">
                <div className="header-top-info mb-2 mb-md-0">
                  <Link to="tel:+23-345-67890">
                    Call Us : <span>0731614240 / 0814628155</span>
                  </Link>
                  <Link to="mailto:info@ncbi.co.za">
                    <i className="fas fa-envelope mr-2"></i>
                    <span>info@ncbi.co.za</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="navbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg px-0 py-0">
                  <Link className="navbar-brand" to="/">
                    <img src={logo} alt="logo" className="logo"/>
                  </Link>

                  <button
                    className="navbar-toggler collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExample09"
                    aria-controls="navbarsExample09"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fa fa-bars"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse text-center"
                    id="navbarsExample09"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <Link className="nav-link" to="/">
                          Home
                        </Link>
                      </li>
                      <li className="nav-item ">
                        <Link className="nav-link" to="about-us">
                          About us
                        </Link>
                      </li>

                      <li className="nav-item ">
                        <Link className="nav-link" to="our-services">
                          Services
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="our-team">
                          Our Team
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="footprint">
                          Footprint
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="our-partners">
                          Partners
                        </Link>
                      </li>
                    </ul>
                    <div className="my-2 my-md-0 ml-lg-4 text-center">
                      <Link
                        to="contact-us"
                        className="btn btn-solid-border btn-round-full"
                      >
                        Get in Touch
                      </Link>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
