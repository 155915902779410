import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Services from "./pages/services";
import About from "./pages/about";
import Team from "./pages/team";
import Footprint from "./pages/footprint";
import Partners from "./pages/partners";
import Contact from "./pages/contact";
import Nav from "./components/nav";
import FooterComponent from "./components/footerComponent";
import Page404 from "./pages/404page";
function App() {
  return (
    <>
      <Router>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/our-services" element={<Services />} />
          <Route path="/our-team" element={<Team />} />
          <Route path="/footprint" element={<Footprint />} />
          <Route path="/our-partners" element={<Partners />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <FooterComponent />
      </Router>
    </>
  );
}
export default App;
