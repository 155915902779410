import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
export default function FooterComponent() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  function HandleSubscribe(e) {
    e.preventDefault();
    if (email == "") {
      setEmail("Email must not be empty");
    } else {
      axios({
        url: "https://ncbi.co.za/endpoints/subscribe.php",
        method: "POST",
        headers: { "content-type": "application/json" },
        data: {
          email: email,
        },
      }).then((response) => {
        if (response.status == "200") {
          setErrorMessage("Subscribed");
        }
      });
    }
  }


  return (
    <>
      <footer className="footer section">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="widget">
                <h4 className="text-capitalize mb-4 text-white">Quick Links</h4>
                <ul className="list-unstyled footer-menu lh-35">
                  <li>
                    <Link to="about-us" className="text-white">
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="our-services" className="text-white">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="footprint" className="text-white">
                      Footprint
                    </Link>
                  </li>
                  <li>
                    <Link to="our-partners" className="text-white">
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="widget">
                <h4 className="text-capitalize mb-4 text-white">Services</h4>
                <ul className="list-unstyled footer-menu lh-35">
                  <li>
                    <Link to="#" className="text-white">
                      Data Quality and Governance
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Data Ingestion
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Data Warehousing and DataMart Expertise
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Business Data Optimization and Transformation
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Data Visualization and Reporting
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Dimensional Modeling
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Process Automation and Scheduling{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Business KPIs / Performance Management
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mx-auto">
              <div className="widget">
                <h4 className="text-capitalize mb-4 text-white">
                  Subscribe Us
                </h4>
                <p className="text-white">
                  Subscribe to get latest news article and resources{" "}
                </p>

                <form action="https://ncbi.co.za/endpoints/subscribe.php" className="sub-form" onSubmit={HandleSubscribe}>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Subscribe Now ..."
                    name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                  />
                  <button type="submit" className="btn btn-main btn-small">
                    subscribe
                  </button>
                  {errorMessage && <p className="text-success">Thank you for Subscribing</p>}
                </form>
              </div>
            </div>
          </div>

          <div className="footer-btm pt-4">
            <div className="row">
              <div className="col-lg-6">
                <div className="copyright text-white">
                  © Copright 2022 Ndabezitha Core BI - All Rights Reserved.
                </div>
              </div>
              <div className="col-lg-6 text-left text-lg-right">
                {/* <ul className="list-inline footer-socials">
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="fab fa-facebook-f mr-2 text-white"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="fab fa-twitter mr-2 text-white"></i>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <i className="fab fa-pinterest-p mr-2 text-white"></i>
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
