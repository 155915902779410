import React from "react";
import { Link } from "react-router-dom";
import AboutComponent from "../components/aboutComponent";
import ServicesCompoent from "../components/servicesComponents";
import FootprintComponent from "../components/footprintComponent";
import PartnerComponent from "../components/partnersComponent";

export default function Home() {
  return (
    <>
      <section className="slider">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-10">
              <div className="block">
                <span className="d-block mb-3 text-white text-capitalize">
                  Business Intelligence
                </span>
                <h1 className="animated fadeInUp mb-5">
                  Ndabezitha Core BI makes data manipulation look easy.
                  Unlocking the Power of Data Intelligence
                </h1>
                <Link
                  to="contact-us"
                  className="btn btn-main animated fadeInUp btn-round-full"
                  aria-label="Get started"
                >
                  Get in touch<i className="btn-icon fa fa-angle-right ml-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutComponent />
      <ServicesCompoent />
      <FootprintComponent />
      <PartnerComponent />
     
    </>
  );
}
