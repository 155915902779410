import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/img/bi.png";

export default function About() {
return (
<>
  <section className="page-title bg-1">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="block text-center">
            <span className="text-white">Company</span>
            <h1 className="text-capitalize mb-4 text-lg">About us</h1>
            <ul className="list-inline">
              <li className="list-inline-item">
                <Link to="/" className="text-white">
                Home
                </Link>
              </li>
              <li className="list-inline-item">
                <span className="text-white">/</span>
              </li>
              <li className="list-inline-item text-white-50">About Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="section about position-relative">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="about-item ">
            <span className="h6 text-color">About us</span>
            <h2 className="mt-3 mb-4 position-relative content-title">
              About Ndabezitha Core BI
            </h2>
            <div className="about-content">
              <p className="mb-5">
                At Ndabezitha Core BI, we are a dynamic team of seasoned
                professionals dedicated to harnessing the transformative
                potential of data. With our wealth of experience and
                expertise, we excel at converting raw data into actionable
                insights, enabling informed decision-making, and offering
                visionary solutions rooted in data elements and patterns.
                Our comprehensive suite of services covers the entire
                Business Intelligence spectrum, including Business Analysis,
                Data Architecture, Business Intelligence Development,
                Database Administration, Process Automation, and Solution
                Testing.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <img src={image}  alt="Business Intelligence" className="w-100"/>
        </div>
      </div>
    </div>
  </section>
  <section className="about-info section pt-20">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="about-info-item mb-4 mb-lg-0">
            <h3 className="mb-3">
              <span className="text-color mr-2 text-md ">Our Vision</span>
            </h3>
            <small>Leading the Data Revolution</small>
            <p>
          Our vision is to be at the forefront of data solutions, offering high-quality, sustainable solutions that
              address real-world data challenges. By uncovering trends, patterns, and behaviors within data, we empower
              decision-makers with the knowledge they need, when they need it.   
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="about-info-item mb-4 mb-lg-0">
            <h3 className="mb-3">
              <span className="text-color mr-2 text-md ">Our Mission</span>
            </h3>
            <small>Empowering Data-Driven Excellence</small>
            <p>
               Our mission is to provide services that enable our clients to predict, analyse, and interpret data
              effectively. We take pride in driving better business operations by delivering real-time insights and
              trends through visualisation and timely reports.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</>
);
}