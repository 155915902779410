import React from "react";
import { Link } from "react-router-dom";
export default function AboutComponent() {
return (
<>
    <section className="section about position-relative">

        <div className="container">
            <div className="row">
                <div className="col-lg-12  ">
                    <div className="about-item ">
                        <span className="h6 text-color">About us</span>
                        <h2 className="mt-3 mb-4 position-relative content-title">
                            About Ndabezitha Core BI
                        </h2>
                        <div className="about-content">
                            <p className="mb-5">
                                At Ndabezitha Core BI, we are a dynamic team of seasoned professionals dedicated to
                                harnessing the transformative potential of data. With our wealth of experience and
                                expertise, we excel at converting raw data into actionable insights, enabling informed
                                decision-making, and offering visionary solutions rooted in data elements and patterns.
                                Our comprehensive suite of services covers the entire Business Intelligence spectrum,
                                including Business Analysis, Data Architecture, Business Intelligence Development,
                                Database Administration, Process Automation, and Solution Testing.
                            </p>
                            <Link to="about-us" className="btn btn-main btn-round-full">
                            Read more
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</>
);
}