import React from "react";
import PartnerComponent from '../components/partnersComponent';
import { Link } from "react-router-dom";
export default function Partners() {
  return (
    <>
      <section className="page-title bg-1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="block text-center">
                <span className="text-white">Work</span>
                <h1 className="text-capitalize mb-4 text-lg">Our Partners</h1>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link to="/" className="text-white">
                      Home
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <span className="text-white">/</span>
                  </li>
                  <li className="list-inline-item text-white-50">
                    Our Partners
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PartnerComponent />
    </>
  );
}
