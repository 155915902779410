import React from "react";

export default function ServicesCompoent() {
  return (
    <>
      <section className="section service">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <div className="section-title">
                <h2 className="text-color">Our Services</h2>
                <p className="mt-3 ">
                  We provide a wide range of services
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Data Quality and Governance</h5>
                <p>Ensuring data accuracy and compliance.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Data Ingestion</h5>
                <p>
                  Leveraging leading-edge tools such as SSIS, Ab Initio, and
                  Informatica.
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5">
                <i className="ti-settings"></i>
                <h5 className="mb-3">
                  Data Warehousing and DataMart Expertise
                </h5>
                <p>Structuring data for optimal utilization.</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5 mb-lg-0">
                <i className="ti-settings"></i>
                <h5 className="mb-3">
                  Business Data Optimization and Transformation
                </h5>
                <p>Utilizing scripting to refine data.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5 mb-lg-0">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Data Visualization and Reporting</h5>
                <p>
                  Delivering insights through SSRS, Power BI, MicroStrategy, and
                  Qlik Sense.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5 mb-lg-0">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Dimensional Modeling</h5>
                <p>Crafting multidimensional models for deep insights.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5 mb-lg-0">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Process Automation and Scheduling</h5>
                <p>Streamlining operations for efficiency.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="service-item mb-5 mb-lg-0">
                <i className="ti-settings"></i>
                <h5 className="mb-3">Business KPIs / Performance Management</h5>
                <p>Measuring success through key performance indicators.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
