import { React } from 'react';
import { Link } from "react-router-dom";
import image from "../assets/img/bi.png";
export default function Page404()
{
    return(
        <>
        <section className="page-title bg-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="block text-center">
                  <span className="text-white">Error</span>
                  <h1 className="text-capitalize mb-4 text-lg">404 Error</h1>
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <Link to="/" className="text-white">
                      Home
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <span className="text-white">/</span>
                    </li>
                    <li className="list-inline-item text-white-50">Error 404</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section about position-relative">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="about-item ">
                  <span className="h6 text-color">Error 404</span>
                  <h2 className="mt-3 mb-4 position-relative content-title text-color">
                    The page that you are looking for does not exist :(
                  </h2>
                </div>
              </div>
             
            </div>
          </div>
        </section>
   
      </>
    )
}