import React from "react";
import logo1 from "../assets/img/logos/Sasol.png";
import logo2 from "../assets/img/logos/MTN.png";
import logo3 from "../assets/img/logos/Nedbank.png";
import logo4 from "../assets/img/logos/Absa.png";
import logo5 from "../assets/img/logos/Digiblu.png";
export default function FootprintComponent() {
  return (
    <section className="section footprint">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 text-center">
            <div className="section-title">
              <h2 className="text-color">Our Footprint</h2>
              <p className="mt-3 ">
                We have left an enduring mark on diverse organisations,
                embodying professionalism and showcasing our skills with each
                project. We take immense pride in the caliber of work we've
                delivered for:
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-2 col-sm-6 ">
           <p className="m-0 text-center"><img className="footprint-image-mobile" alt="Footprint" src={logo1}/></p>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6">
          <p className="m-0 text-center"><img className="footprint-image-mobile" alt="Footprint" src={logo2}/></p>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6">
          <p className="m-0 text-center"><img className="footprint-image-mobile" alt="Footprint" src={logo3}/></p>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 ">
          <p className="m-0 text-center"> <img className="footprint-image-mobile" alt="Footprint" src={logo4}/></p>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 ">
          <p className="m-0 text-center"> <img className="footprint-image-mobile" alt="Footprint" src={logo5}/> </p>
          </div>
        </div>
      </div>
    </section>
  );
}
