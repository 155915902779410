import React from "react";
import logo1 from "../assets/img/logos/Afrihost.png";
import logo2 from "../assets/img/logos/Microsoft.png";
export default function PartnerComponent() {
  return (
    <>
      <section className="section service">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div className="section-title">
                <h2 className="text-color">Our Partners</h2>
                <p className="mt-3 ">
                  Our commitment to excellence is fortified by strategic
                  partnerships that keep our infrastructure cutting-edge and
                  operational. We are proudly hosted by Afrihost, and our
                  operations are strengthened by the support of Microsoft,
                  aligning seamlessly with our focus on delivering top-tier
                  solutions within the Microsoft ecosystem.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <img className="footprint-image" alt="Footprint" src={logo1} />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 col-6">
              <img className="footprint-image" alt="Footprint" src={logo2} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
