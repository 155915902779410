import React from "react";
import { Link } from "react-router-dom";
import team_01 from "../assets/img/team/team-1.jpg";
import team_02 from "../assets/img/team/team-2.jpg";
import team_03 from "../assets/img/team/team-3.jpg";
import team_04 from "../assets/img/team/team-4.jpg";
import team_05 from "../assets/img/team/team-5.jpg";
import team_06 from "../assets/img/team/team-6.jpg";
export default function TeamComponent() {
  return (
    <>
      <section className="section team">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <div className="section-title">
                <span className="h6 text-color">Our Team</span>
                <p className="mt-3">
                  We are more than just data experts; we are partners in your
                  journey to data-driven success. Let us guide you through the
                  data revolution and turn your information into actionable
                  insights.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_01}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                     
                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">
                    Nkosinathi Shandu
                  </h4>
                  <p>Director & Senior BI Developer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_02}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                     
                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">
                    Siyabonga Mbonambi
                  </h4>
                  <p>Senior Solutions Architect</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5 ">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_03}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                     
                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">Irvin Ncwane</h4>
                  <p>Senior Data Engineer</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5 mb-lg-0">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_04}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                     
                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">Andiswa Ziqubu</h4>
                  <p>Business Analyst</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5 mb-lg-0">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_05}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                     
                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">Collen Nkosi</h4>
                  <p>Process Automation Engineer</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="team-item-wrap mb-5 mb-lg-0 ">
                <div className="team-item position-relative">
                  <img
                    loading="lazy"
                    src={team_06}
                    alt="team"
                    className="img-fluid w-100"
                  />
                  <div className="team-img-hover">
                    <ul className="team-social list-inline">
                      <li className="list-inline-item">
                        <Link href="#" className="facebook">
                          <i
                            className="fab fa-facebook-f"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link href="#" className="linkedin">
                          <i
                            className="fab fa-linkedin-in"
                            aria-hidden="true"
                          ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="team-item-content">
                  <h4 className="mt-3 mb-0 text-capitalize">Mmabatho Magoro</h4>
                  <p>Senior Business Analyst</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
